/**
 * @author David Bootle
 * This file is intended as a wrapper to make getting and setting various user settings easier.
 * Rather than having to deal with the entire API call each time, this function will create a nice class object that has getters and settings for managing it.
 * If any error occurs, the class will set it's "errorFlag" to true. Pages should watch for this to know that settings are not working correctly.
 */

import { useMemo } from "react";
import {
    getDefaultAvailability,
    getDefaultAvailabilityEnabled,
    getEmailNotificationEnabled,
    getShowDefaultAvailabilityExplainer,
    setDefaultAvailability,
    setDefaultAvailabilityEnabled, setEmailNotificationEnabled,
    setShowDefaultAvailabilityExplainer
} from "./APIs";

/**
 * Represents a single setting. Caches its values and automatically reloads if it can.
 */
class Setting {

    constructor(name, getFunction, setFunction, currentUser, defaultValue, testMode = false) {
        this.name = name;
        this.getter = getFunction;
        this.setter = setFunction;
        this.currentUser = currentUser;
        this.testMode = testMode;
        this.default = defaultValue;
        
        this.cached = false;
        this.cachedValue = null;
        this.errorFlag = false;
    }

    /** Getter function that returns the value of the setting. */
    get() {
        // return cached value if cached
        if (this.cached) {
            return this.cachedValue;
        }

        // if test mode, grab from localhost
        if (this.testMode) {
            return JSON.parse(localStorage.getItem(`${this.currentUser.uid}-${this.name}`)) ?? this.default;
        }

        // if not cached, call the getter function
        return new Promise(async (resolve, reject) => {
            await this.getter(this.currentUser?.getIdToken(), {
                onSuccess: (data) => {
                    this.cached = true;
                    this.cachedValue = data;
                    this.errorFlag = false;
                    resolve(data);
                },
                onOtherResponse: (response) => {
                    this.errorFlag = response;
                    this.cached = false;
                    this.cachedValue = null;
                    reject(response);
                },
                onClientError: (error) => {
                    this.errorFlag = error;
                    this.cached = false;
                    this.cachedValue = null;
                    reject(error);
                }
            })
        })
    }

    /**
     * Setter value that sets the value of the setting.
     */
    set(newVal) {
        // if in test mode, grab data
        if (this.testMode) {
            localStorage.setItem(`${this.currentUser.uid}-${this.name}`, JSON.stringify(newVal));
            return;
        }

        return new Promise(async (resolve, reject) => {
            await this.setter(this.currentUser?.getIdToken(), newVal, {
                onSuccess: (data) => {
                    // if successful, update the cache without requiring a new server call
                    this.cached = true;
                    this.cachedValue = newVal;
                    resolve(data);
                },
                onOtherResponse: (response) => {
                    // clear cache
                    this.cached = false;
                    this.cachedValue = null;
                    this.errorFlag = response;
                    reject(response);
                },
                onClientError: (error) => {
                    // clear cache
                    this.cached = false;
                    this.cachedValue = null;
                    this.errorFlag = error;
                    reject(error);
                }
            })
        })
    }

}

/**
 * A wrapper that allows for easy getting and setting of server side data at the risk of losing some error handling functionality.
 */
export class SettingsAPIWrapper {

    #defaultAvailabilitySetting;
    #defaultAvailabilityEnabledSetting;
    #showDefaultAvailabilityExplainerSetting;
    #emailNotificationEnabled;

    /**
     * Sets up the internal values for the wrapper.
     * @param {} currentUser - A function that when called will return the valid authentication token for the user. Probably authContext.currentUser.getIdToken
     * @param {bool} [testMode=false] Whether or not to enable testing mode. When testing mode is enabled, settings are stored offline using localStorage when possible.
     */
    constructor(currentUser, testMode = false) {
        
        // create list of settings
        this.#defaultAvailabilitySetting = new Setting("DefaultAvailability", getDefaultAvailability, setDefaultAvailability, currentUser, {}, testMode);
        this.#defaultAvailabilityEnabledSetting = new Setting("DefaultAvailabilityEnabled", getDefaultAvailabilityEnabled, setDefaultAvailabilityEnabled, currentUser, true, testMode);
        this.#showDefaultAvailabilityExplainerSetting = new Setting("ShowDefaultAvailabilityExplainer", getShowDefaultAvailabilityExplainer, setShowDefaultAvailabilityExplainer, currentUser, true, testMode);
        this.#emailNotificationEnabled = new Setting("EmailNotificationEnabled", getEmailNotificationEnabled, setEmailNotificationEnabled, currentUser, true, testMode);
    }
    
    /**
     * The user's default availability.
     * @returns {import("./dataProcessing").DefaultData}
     */
    get defaultAvailability() {
        return this.#defaultAvailabilitySetting.get();
    }

    /**
     * Asynchronous function to update the default availability.
     * @param {import("./dataProcessing").DefaultData} newVal 
     */
    setDefaultAvailability(newVal) {
        return this.#defaultAvailabilitySetting.set(newVal);
    }

    /**
     * Whether or not the user has default availability enabled.
     * @returns {bool} - True if enabled, false if not
     */
    get defaultAvailabilityEnabled() {
        return this.#defaultAvailabilityEnabledSetting.get();
    }

    /**
     * Asynchronous function to update whether or not the user has default availability enabled.
     * Set to true to enable default availability, set to false to disable it.
     * @param {bool} newVal 
     */
    setDefaultAvailabilityEnabled(newVal) {
        return this.#defaultAvailabilityEnabledSetting.set(newVal);
    }

    get emailNotificationEnabled() {
        return this.#emailNotificationEnabled.get();
    }

    setEmailNotificationEnabled(newVal) {
        return this.#emailNotificationEnabled.set(newVal);
    }

    /**
     * Whether or not the user has default availability enabled.
     * @returns {bool} - True if enabled, false if not
     */
    get showDefaultAvailabilityExplainer() {
        return this.#showDefaultAvailabilityExplainerSetting.get();
    }

    /**
     * Asynchronous function to update whether or not the user has default availability enabled.
     * Set to true to enable default availability, set to false to disable it.
     * @param {bool} newVal 
     */
    setShowDefaultAvailabilityExplainer(newVal) {
        return this.#showDefaultAvailabilityExplainerSetting.set(newVal);
    }
}

/**
 * Handles the creation and storage of the settings api wrapper.
 * @param {} currentUser - The current user from the auth context
 * @returns {SettingsAPIWrapper}
 */
export default function useServerSettings(currentUser) {
    return useMemo(() => {
        if (!currentUser) {
            return null;
        }

        // THE SECOND ARGUMENT IS TEST MODE. SET TO FALSE TO USE THE ACTUAL API
        return new SettingsAPIWrapper(currentUser, false);
    }, [currentUser]);
}