import * as React from "react"
const UserCheckIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        viewBox="0 0 24 24"
        {...props}
    >
        <defs>
            <style>
                {
                    ".cls-1{stroke:#000;fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}"
                }
            </style>
        </defs>
        <path
            d="M16 21v-2c0-2.21-1.79-4-4-4H5c-2.21 0-4 1.79-4 4v2"
            className="cls-1"
        />
        <circle cx={8.5} cy={7} r={4} className="cls-1" />
        <path
            d="m17 11 2 2 4-4"
            style={{
                stroke: "#6cbb9e",
                fill: "none",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: 2,
            }}
        />
    </svg>
)
export default UserCheckIcon
