/**
 * This file contains wrappers for many of the APIs that are used in Overlap.
 * Anywhere custom API that must be used more than once should be defined here.
 */

import { constants } from "../firebase";
import { v4 as uuidv4 } from 'uuid';

/**
 * @typedef {Object} APIResponse
 * @property {boolean} success Whether or not the request was successful.
 * @property {string?} fault The fault the request failed. Either 'CLIENT' or 'SERVER'.
 * @property {number?} code The HTTP response code of the request.
 * @property {Object?} data The decoded data from the server.
 * @property {Object?} response The response from the server.
 * @property {Error?} error The error that was thrown.
 */

/**
 * @typedef EpochData - Represents data from the backend.
 * @property {EpochRange[]} range
 * @property {EpochMember[]} members
 */

/**
 * Fetch meeting overlaps information from the server. This wrapper function calls the meeting overlaps API. The wrapper is used in order to standarize the calling of this API and allow for quick and rapid changes.
 * @param {string} meetingID The ID of the meeting to fetch information for.
 * @param {string} [authToken=null] The Bearer token to use for authentication. If useAuth is false, this is ignored.
 * @param {Object} [options={}] Options for the request.
 * @param {boolean} [options.useAuth=true] Whether or not to include the Authorization header.
 * @param {Function} [options.onSuccess=(data)=>{}] Callback function to be ran on success. This function is passed the decoded data from the server.
 * @param {Function} [options.onMeetingNotFound=(data)=>{}] - Callback function to be run if the server returns 404. This function is passed the decoded data from the server.
 * @param {Function} [options.onOtherResponse=(res)=>{}] - Callback function to be run if the server returns anything that was not expected. This function is passed the response from the server.
 * @param {Function} [options.onClientError=(err)=>{}] - Callback function to be run if this function throws an error. This function is passed the error.
 * @returns {Promise<APIResponse>} Response information from the server.
 */
export async function getMeetingOverlaps(meetingID, authToken = null, { useAuth = true, onSuccess = (data)=>{}, onMeetingNotFound = (data)=>{}, onOtherResponse = (res)=>{}, onClientError = (err)=>{} }) {
    try {
        // setup request options to be empty if useAuth is false and to include the Authorization header if useAuth is true
        let requestOptions = useAuth ? { headers: { Authorization: `Bearer ${await authToken}` } } : {};
        let requestUrl = useAuth ? `${constants.functionsBaseUrl}/meeting/${meetingID}/overlaps` : `${constants.functionsBaseUrl}/meeting/${meetingID}/guest/overlaps`;

        // fetch meeting overlap information from the server and decode
        let response = await fetch(requestUrl, requestOptions);
        response = await response.json();
        let data = response.data;

        // if successful, send success callback and return success
        if (response.code === 200) {
            onSuccess(data);
            return {
                'success': true,
                'fault': 'SERVER',
                'code': response.status,
                'data': data,
            }
        }

        // if no meeting was found, send not found callback and return not found
        else if (response.status === 404) {
            onMeetingNotFound(data);
            return {
                'success': false,
                'fault': 'SERVER',
                'code': 404,
                'data': data,
            }
        }

        // if some other response is returned, send server error callback and return server error
        else {
            onOtherResponse(response);
            return {
                'success': false,
                'fault': 'SERVER',
                'code': response.status,
                'response': response,
            }
        }
    }

    // if something went wrong while fetching, send client error callback and return client error
    catch (err) {
        onClientError(err);
        return {
            'success': false,
            'fault': 'CLIENT',
            'code': 500,
            'error': err,
        }
    }
}

/**
 * Instructs the server to add a new user to a meeting, either a guest or a normal user.
 * If the user is already in the meeting, the server will return 409 and this function will execute the
 * onUserAlreadyInMeeting callback. If the user is added successfully with their availability, then the
 * onSuccess callback is executed.
 * @param {string} meetingID The ID of the meeting to fetch information for.
 * @param {string} [authToken=null] The Bearer token to use for authentication. If isGuest is true, this is ignored.
 * @param {EpochData[]} [availability=[]] The epochData availability of the user.
 * @param {string} [guestUsername=null] The username of the guest user. If the user is not a guest, this value is ignored.
 * @param {string} [guestAccessCode=null] The access code of the guest user. If the user is not a guest, this value is ignored.
 * @param {Object} [options={}] Options for the request.
 * @param {boolean} [options.isGuest=false] Whether or not the user is a guest.
 * @param {Function} [options.onSuccess=(data)=>{}] Callback function to be ran on success. This function is passed the decoded data from the server.
 * @param {Function} [options.onUserAlreadyInMeeting=(data)=>{}] - Callback function to be run if the server returns 409. This function is passed the decoded data from the server.
 * @param {Function} [options.onOtherResponse=(res)=>{}] - Callback function to be run if the server returns anything that was not expected. This function is passed the response from the server.
 * @param {Function} [options.onClientError=(err)=>{}] - Callback function to be run if this function throws an error. This function is passed the error.
 * @returns {Promise<APIResponse>} Response information from the server.
 */
export async function joinMeeting(meetingID, authToken = null, availability = [], guestUsername = null, guestAccessCode = null, { isGuest = false, onSuccess = (data)=>{}, onUserAlreadyInMeeting = (data)=>{}, onOtherResponse = (response)=>{}, onClientError = (error)=>{} }) {

    // if no access code was given, generate a random access code
    if (guestAccessCode === '') {
        guestAccessCode = uuidv4();
    }

    try {
        // setup request options to be empty if useAuth is false and to include the Authorization header if useAuth is true
        let requestOptions;
        if (isGuest) {
            requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'availability': availability,
                    'username': guestUsername,
                    'password': guestAccessCode,
                }),
            }
        } else {
            requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${await authToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'availability': availability,
                }),
            }
        }
        let requestUrl = !isGuest ? `${constants.functionsBaseUrl}/meeting/${meetingID}/join` : `${constants.functionsBaseUrl}/meeting/${meetingID}/guest/join`;

        let response = await fetch(requestUrl, requestOptions);
        response = await response.json();
        let data = response.data;

        // if successful, send success callback and return success
        if (response.code === 200) {
            onSuccess(data);
            return {
                'success': true,
                'fault': 'SERVER',
                'code': response.status,
                'data': data,
            }
        }

        // if the user is already in the meeting, then 409 will be returned.
        if (response.code === 409) {
            onUserAlreadyInMeeting(data);
            return {
                'success': false,
                'fault': 'SERVER',
                'code': 409,
                'data': data
            }
        }

        // if some other response is returned, send server error callback and return server error
        else {
            onOtherResponse(response);
            return {
                'success': false,
                'fault': 'SERVER',
                'code': response.status,
                'response': response,
            }
        }
    }

    catch (err) {
        // if there was an error on the client side performing the operations, return client error
        onClientError(err);
        return {
            'success': false,
            'fault': 'CLIENT',
            'code': 500,
            'error': err,
        }
    }
}

/**
 * Instructs the server to update the availability of an existing member of a meeting. 
 * @param {string} meetingID The ID of the meeting to fetch information for.
 * @param {string} [authToken=null] The Bearer token to use for authentication. If isGuest is true, this is ignored.
 * @param {EpochData[]} [availability=[]] The epochData availability of the user.
 * @param {string} [guestUsername=null] The username of the guest user. If the user is not a guest, this value is ignored.
 * @param {string} [guestAccessCode=null] The access code of the guest user. If the user is not a guest, this value is ignored.
 * @param {Object} [options={}] Options for the request.
 * @param {boolean} [options.isGuest=false] Whether or not the user is a guest.
 * @param {Function} [options.onSuccess=(data)=>{}] Callback function to be ran on success. This function is passed the decoded data from the server.
 * @param {Function} [options.onOtherResponse=(res)=>{}] - Callback function to be run if the server returns anything that was not expected. This function is passed the response from the server.
 * @param {Function} [options.onClientError=(err)=>{}] - Callback function to be run if this function throws an error. This function is passed the error.
 * @returns {Promise<APIResponse>} Response information from the server.
 */
export async function updateMeetingAvailability(meetingID, authToken = null, availability = [], guestUsername = null, guestAccessCode = null, { isGuest = false, onSuccess = (data)=>{}, onOtherResponse = (response)=>{}, onClientError = (error)=>{} }) {

    // if no access code was given, generate a random access code
    if (guestAccessCode === '') {
        guestAccessCode = uuidv4();
    }

    try {
        // setup request options to be empty if useAuth is false and to include the Authorization header if useAuth is true
        let requestOptions;
        if (isGuest) {
            requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'availability': availability,
                    'username': guestUsername,
                    'password': guestAccessCode,
                }),
            }
        } else {
            requestOptions = {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${await authToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'availability': availability,
                }),
            }
        }
        let requestUrl = !isGuest ? `${constants.functionsBaseUrl}/meeting/${meetingID}/join` : `${constants.functionsBaseUrl}/meeting/${meetingID}/guest/join`;

        let response = await fetch(requestUrl, requestOptions);
        response = await response.json();
        let data = response.data;

        // if successful, send success callback and return success
        if (response.code === 200) {
            onSuccess(data);
            return {
                'success': true,
                'fault': 'SERVER',
                'code': response.status,
                'data': data,
            }
        }

        // if some other response is returned, send server error callback and return server error
        else {
            onOtherResponse(response);
            return {
                'success': false,
                'fault': 'SERVER',
                'code': response.status,
                'response': response,
            }
        }
    }

    catch (err) {
        // if there was an error on the client side performing the operations, return client error
        onClientError(err);
        return {
            'success': false,
            'fault': 'CLIENT',
            'code': 500,
            'error': err,
        }
    }
}

/**
 * Get the status of a guest user in a given meeting.
 * @param {string} meetingID - The meeting ID of the meeting to check.
 * @param {*} guestUsername - The username of the guest to check for.
 * @param {*} guestAccessCode - The access code of the guest to check for.
 */
export async function validateGuestCredentials(meetingID, guestUsername, guestAccessCode, { guestAccessCodeValid = (data)=>{}, guestAccessCodeInvalid = (data)=>{}, guestNotInMeeting = (data)=>{}, onOtherResponse = (response)=>{}, onClientError = (error)=>{} }) {

    // if no access code was given, generate a random access code
    if (guestAccessCode === '') {
        guestAccessCode = uuidv4();
    }

    try {
        let response = await fetch(`${constants.functionsBaseUrl}/meeting/${meetingID}/guest/participant/self`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'username': guestUsername,
                'password': guestAccessCode,
            }),
        });
        response = await response.json();

        if (response.code === 200) {
            guestAccessCodeValid(response);
            return {
                'success': true,
                'fault': 'SERVER',
                'code': response.status,
                'data': response,
            }
        }

        else if (response.code === 401 && response.message === "not a participant") {
            guestNotInMeeting(response);
            return {
                'success': false,
                'fault': 'SERVER',
                'code': 401,
                'data': null,
            }
        }

        else if (response.code === 401 && response.message === "password invalid") {
            guestAccessCodeInvalid(response);
            return {
                'success': false,
                'fault': 'SERVER',
                'code': 401,
                'data': null,
            }
        }

        else {
            onOtherResponse(response);
            return {
                'success': false,
                'fault': 'SERVER',
                'code': response.status,
                'response': response,
            }
        }
    }

    catch (e) {
        onClientError(e);
        return {
            'success': false,
            'fault': 'CLIENT',
            'code': 500,
            'error': e,
        }
    }
}

/**
 * Get a given user's default availability.
 * @param {*} authToken The user's authentication token.
 * @param {Object} [options={}] Options for the request.
 * @param {Function} [options.onSuccess=(data)=>{}] Callback function to be ran on success. This function is passed the decoded data from the server.
 * @param {Function} [options.onOtherResponse=(res)=>{}] - Callback function to be run if the server returns anything that was not expected. This function is passed the response from the server.
 * @param {Function} [options.onClientError=(err)=>{}] - Callback function to be run if this function throws an error. This function is passed the error.
 * @returns {APIResponse}
 */
export async function getDefaultAvailability(authToken, { onSuccess = (data)=>{}, onOtherResponse = (response)=>{}, onClientError = (error)=>{} }) {
    try {
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${await authToken}`,
            },
        };

        let response = await fetch(`${constants.functionsBaseUrl}/users/self/settings/?name=defAvail`, requestOptions);
        response = await response.json();

        if (response.code === 200) {
            onSuccess(response.data[0].value);
            return {
                'success': true,
                'fault': 'SERVER',
                'code': response.status,
                'data': response.data,
            }
        }

        else {
            onOtherResponse(response);
            return {
                'success': false,
                'fault': 'SERVER',
                'code': response.status,
                'response': response,
            }
        }
    }
    catch (e) {
        onClientError(e);
        return {
            'success': false,
            'fault': 'CLIENT',
            'code': 500,
            'error': e,
        }
    }
}

/**
 * 
 * @param {string} authToken The authentication token of the user.
 * @param {import('./dataProcessing').DefaultData} defaultAvailability The default availability of the user. This can either be null or a list of EpochData objects.
 * @param {Object} [options={}] Options for the request.
 * @param {Function} [options.onSuccess=(data)=>{}] Callback function to be ran on success. This function is passed the decoded data from the server.
 * @param {Function} [options.onOtherResponse=(res)=>{}] - Callback function to be run if the server returns anything that was not expected. This function is passed the response from the server.
 * @param {Function} [options.onClientError=(err)=>{}] - Callback function to be run if this function throws an error. This function is passed the error.
 * @returns {APIResponse}
 */
export async function setDefaultAvailability(authToken, defaultAvailability, { onSuccess = (data)=>{}, onOtherResponse = (response)=>{}, onClientError = (error)=>{} }) {
    try {
        let requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${await authToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: "defAvail",
                value: defaultAvailability
            }),
        };

        let response = await fetch(`${constants.functionsBaseUrl}/users/self/settings/`, requestOptions);
        response = await response.json();

        if (response.code === 200) {
            onSuccess(response.data);
            return {
                'success': true,
                'fault': 'SERVER',
                'code': response.status,
                'data': response.data,
            }
        }

        else {
            onOtherResponse(response);
            return {
                'success': false,
                'fault': 'SERVER',
                'code': response.status,
                'response': response,
            }
        }
    }
    catch (e) {
        onClientError(e);
        return {
            'success': false,
            'fault': 'CLIENT',
            'code': 500,
            'error': e,
        }
    }
}

/**
 * Get whether the user has default availability enabled.
 * @param {*} authToken The user's authentication token.
 * @param {Object} [options={}] Options for the request.
 * @param {Function} [options.onSuccess=(data)=>{}] Callback function to be ran on success. This function is passed the decoded data from the server.
 * @param {Function} [options.onOtherResponse=(res)=>{}] - Callback function to be run if the server returns anything that was not expected. This function is passed the response from the server.
 * @param {Function} [options.onClientError=(err)=>{}] - Callback function to be run if this function throws an error. This function is passed the error.
 * @returns {APIResponse}
 */
export async function getDefaultAvailabilityEnabled(authToken, { onSuccess = (data)=>{}, onOtherResponse = (response)=>{}, onClientError = (error)=>{} }) {
    try {
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${await authToken}`,
            },
        };

        let response = await fetch(`${constants.functionsBaseUrl}/users/self/settings/?name=defAvailEnabled`, requestOptions);
        response = await response.json();

        if (response.code === 200) {
            onSuccess(response.data[0].value);
            return {
                'success': true,
                'fault': 'SERVER',
                'code': response.status,
                'data': response.data,
            }
        }

        else {
            onOtherResponse(response);
            return {
                'success': false,
                'fault': 'SERVER',
                'code': response.status,
                'response': response,
            }
        }
    }
    catch (e) {
        onClientError(e);
        return {
            'success': false,
            'fault': 'CLIENT',
            'code': 500,
            'error': e,
        }
    }
}

/**
 * 
 * @param {string} authToken The authentication token of the user.
 * @param {bool} defaultAvailabilityEnabled Whether or not the user has default availability enabled.
 * @param {Object} [options={}] Options for the request.
 * @param {Function} [options.onSuccess=(data)=>{}] Callback function to be ran on success. This function is passed the decoded data from the server.
 * @param {Function} [options.onOtherResponse=(res)=>{}] - Callback function to be run if the server returns anything that was not expected. This function is passed the response from the server.
 * @param {Function} [options.onClientError=(err)=>{}] - Callback function to be run if this function throws an error. This function is passed the error.
 * @returns {APIResponse}
 */
export async function setDefaultAvailabilityEnabled(authToken, defaultAvailabilityEnabled, { onSuccess = (data)=>{}, onOtherResponse = (response)=>{}, onClientError = (error)=>{} }) {
    try {
        let requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${await authToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: "defAvailEnabled",
                value: defaultAvailabilityEnabled
            }),
        };

        let response = await fetch(`${constants.functionsBaseUrl}/users/self/settings/`, requestOptions);
        response = await response.json();

        if (response.code === 200) {
            onSuccess(response.data);
            return {
                'success': true,
                'fault': 'SERVER',
                'code': response.status,
                'data': response.data,
            }
        }

        else {
            onOtherResponse(response);
            return {
                'success': false,
                'fault': 'SERVER',
                'code': response.status,
                'response': response,
            }
        }
    }
    catch (e) {
        onClientError(e);
        return {
            'success': false,
            'fault': 'CLIENT',
            'code': 500,
            'error': e,
        }
    }
}

/**
 * Get whether to show the default availability explainer to the user.
 * @param {*} authToken The user's authentication token.
 * @param {Object} [options={}] Options for the request.
 * @param {Function} [options.onSuccess=(data)=>{}] Callback function to be ran on success. This function is passed the decoded data from the server.
 * @param {Function} [options.onOtherResponse=(res)=>{}] - Callback function to be run if the server returns anything that was not expected. This function is passed the response from the server.
 * @param {Function} [options.onClientError=(err)=>{}] - Callback function to be run if this function throws an error. This function is passed the error.
 * @returns {APIResponse}
 */
export async function getShowDefaultAvailabilityExplainer(authToken, { onSuccess = (data)=>{}, onOtherResponse = (response)=>{}, onClientError = (error)=>{} }) {
    try {
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${await authToken}`,
            },
        };

        let response = await fetch(`${constants.functionsBaseUrl}/users/self/settings/?name=defAvailBannerShow`, requestOptions);
        response = await response.json();

        if (response.code === 200) {
            onSuccess(response.data[0].value);
            return {
                'success': true,
                'fault': 'SERVER',
                'code': response.status,
                'data': response.data,
            }
        }

        else {
            onOtherResponse(response);
            return {
                'success': false,
                'fault': 'SERVER',
                'code': response.status,
                'response': response,
            }
        }
    }
    catch (e) {
        onClientError(e);
        return {
            'success': false,
            'fault': 'CLIENT',
            'code': 500,
            'error': e,
        }
    }
}

export async function getEmailNotificationEnabled(authToken, { onSuccess = (data)=>{}, onOtherResponse = (response)=>{}, onClientError = (error)=>{} }) {
    try {
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${await authToken}`,
            },
        };

        let response = await fetch(`${constants.functionsBaseUrl}/users/self/settings/?name=emailNotifEnabled`, requestOptions);
        response = await response.json();

        if (response.code === 200) {
            onSuccess(response.data[0].value);
            return {
                'success': true,
                'fault': 'SERVER',
                'code': response.status,
                'data': response.data,
            }
        }

        else {
            onOtherResponse(response);
            return {
                'success': false,
                'fault': 'SERVER',
                'code': response.status,
                'response': response,
            }
        }
    }
    catch (e) {
        onClientError(e);
        return {
            'success': false,
            'fault': 'CLIENT',
            'code': 500,
            'error': e,
        }
    }
}

/**
 * 
 * @param {string} authToken The authentication token of the user.
 * @param {bool} showDefaultAvailabilityExplainer Whether or not to show the default availability explainer to the user.
 * @param {Object} [options={}] Options for the request.
 * @param {Function} [options.onSuccess=(data)=>{}] Callback function to be ran on success. This function is passed the decoded data from the server.
 * @param {Function} [options.onOtherResponse=(res)=>{}] - Callback function to be run if the server returns anything that was not expected. This function is passed the response from the server.
 * @param {Function} [options.onClientError=(err)=>{}] - Callback function to be run if this function throws an error. This function is passed the error.
 * @returns {APIResponse}
 */
export async function setShowDefaultAvailabilityExplainer(authToken, showDefaultAvailabilityExplainer, { onSuccess = (data)=>{}, onOtherResponse = (response)=>{}, onClientError = (error)=>{} }) {
    try {
        let requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${await authToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: "defAvailBannerShow",
                value: showDefaultAvailabilityExplainer
            }),
        };

        let response = await fetch(`${constants.functionsBaseUrl}/users/self/settings/`, requestOptions);
        response = await response.json();

        if (response.code === 200) {
            onSuccess(response.data);
            return {
                'success': true,
                'fault': 'SERVER',
                'code': response.status,
                'data': response.data,
            }
        }

        else {
            onOtherResponse(response);
            return {
                'success': false,
                'fault': 'SERVER',
                'code': response.status,
                'response': response,
            }
        }
    }
    catch (e) {
        onClientError(e);
        return {
            'success': false,
            'fault': 'CLIENT',
            'code': 500,
            'error': e,
        }
    }
}

export async function setEmailNotificationEnabled(authToken, value, { onSuccess = (data)=>{}, onOtherResponse = (response)=>{}, onClientError = (error)=>{} }) {
    try {
        let requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${await authToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: "emailNotifEnabled",
                value
            }),
        };

        let response = await fetch(`${constants.functionsBaseUrl}/users/self/settings/`, requestOptions);
        response = await response.json();

        if (response.code === 200) {
            onSuccess(response.data);
            return {
                'success': true,
                'fault': 'SERVER',
                'code': response.status,
                'data': response.data,
            }
        }

        else {
            onOtherResponse(response);
            return {
                'success': false,
                'fault': 'SERVER',
                'code': response.status,
                'response': response,
            }
        }
    }
    catch (e) {
        onClientError(e);
        return {
            'success': false,
            'fault': 'CLIENT',
            'code': 500,
            'error': e,
        }
    }
}