import * as React from "react"
const UserXIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        viewBox="0 0 24 24"
        {...props}
    >
        <defs>
            <style>
                {
                    ".cls-1,.cls-2{stroke:#000;fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}.cls-2{stroke:#ea493a}"
                }
            </style>
        </defs>
        <path
            d="M16 21v-2c0-2.21-1.79-4-4-4H5c-2.21 0-4 1.79-4 4v2"
            className="cls-1"
        />
        <circle cx={8.5} cy={7} r={4} className="cls-1" />
        <path d="m18 8 5 5M23 8l-5 5" className="cls-2" />
    </svg>
)
export default UserXIcon
